.navbar {
  @apply relative md:absolute z-50 min-w-full bg-black md:bg-transparent md:bg-gradient-to-b from-fade-from to-transparent text-white font-loos;
}

.navbar ul li ul {
  @apply absolute;
}

.navbar ul li ul li:hover {
  @apply bg-neutral-4;
}

.navbar li + li {
  @apply mt-0;
}

.navbar ul > li > ul {
  @apply m-0 p-0;
}

.navbar nav a {
  @apply text-white no-underline;
}

.navbar nav ul {
  @apply list-none;
}

.navbar nav ul li ul li {
  @apply m-0;
}