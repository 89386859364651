.flipcard {
  @apply w-full aspect-square;
  perspective: 1000px;
}

.flipcardInner {
  @apply relative w-full h-full text-center transition duration-700;
  transform-style: preserve-3d;
}

.flipped .flipcardInner {
  transform: rotateX(180deg);
}

.flipcardFront,
.flipcardBack {
  @apply absolute w-full h-full p-4 text-center rounded-tl-3xl rounded-br-3xl shadow flex flex-col justify-center overflow-hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flipcardFront {
  @apply text-xl lg:text-2xl font-loos font-bold;
}

.flipcardBack {
  @apply relative bg-primary-1 text-black;
  transform: rotateX(180deg);
}

.flipcardResult {
  @apply relative w-full max-h-full px-4 overflow-y-auto;
}

.flippedY {
  transform: rotateY(180deg);
}

.perspective {
  perspective: 1000px;
}

.perserve3d {
  transform-style: preserve-3d;
  @apply transition duration-700;
}

.backface {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
