@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth md:snap-y md:snap-mandatory;
  }

  body {
    @apply font-base bg-black text-white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-loos font-bold;
  }

  p + p,
  p + ul,
  ul + p {
    @apply mt-4;
  }

  li + li {
    @apply mt-2;
  }

  p > a,
  li > a,
  strong > a {
    @apply underline underline-offset-2 text-primary-3;
  }

  ul {
    @apply list-disc list-outside ml-6;
  }

  ul > li > ul {
    @apply px-8 mt-2;
  }
}

@layer components {
  .custom-checkbox input:checked + div > div {
    @apply visible;
  }
  .custom-checkbox input:checked + div {
    @apply border-primary-1;
  }
}

@layer utilities {
  .text-shadow-dk {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  }
}

input:checked + label > .subject-button {
  @apply bg-opacity-50;
}

input:checked + label > .subject-button .circle-check {
  @apply visible;
}

.topic-map:before {
  content: '';
  z-index: 0;
  @apply absolute top-12 bottom-12 left-12 w-0.5 bg-white;
  @apply lg:hidden;
}
